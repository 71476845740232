import { Injectable } from '@angular/core';
import { IslQueryRequest } from '@sealights/sl-query-builder';
import { md5 } from 'hash-wasm';
import { Observable, of } from 'rxjs';

import { filterData } from '../../sl-table/mocks/table-mock';
import { TableResponse } from '../../sl-table/models/sl-table.model';
import { SLLocalStorageKey } from '../models/SLTableStorageKey';


@Injectable({
	providedIn: null
})
export class SlLocalStorageService {

	saveState(key: string, state: any): void {
		localStorage.setItem(key, JSON.stringify(state));
	}

	loadState(key: string): [] {
		const state = localStorage.getItem(key);
		return state ? JSON.parse(state) as [] : [];
	}

	removeLocalStorageListItem<T>(key: SLLocalStorageKey, id: string): void {
		const list = this.loadState(key) as [];
		const findIndex = this.findItem(list, id);
		if (findIndex !== -1) {
			list.splice(findIndex, 1);
			this.saveState(key, list);
		}
	}

	async saveLocalStorageListItem<T>(key: SLLocalStorageKey, object: T, id?: string): Promise<void> {
		const list = this.loadState(key) as any[];
		const _id = id || await md5(JSON.stringify(object));
		const findIndex = this.findItem(list, _id);
		if (findIndex === -1) {
			list.unshift({ ...object, _id });
		} else {
			list.splice(findIndex, 1, { ...object, _id });
		}
		this.saveState(key, list);
		return;
	}

	totalItems(key: SLLocalStorageKey): number {
		const list = this.loadState(key);
		return list.length;
	}

	loadLocalStorageListItem<T>(key: string, id: string): T {
		const list = this.loadState(key) as any[];
		const findIndex = this.findItem(list, id);
		return list[findIndex] as T;
	}

	loadLocalStorageList<T>(key: string, query: IslQueryRequest<undefined, T>): Observable<TableResponse<T>> {
		const list = this.loadState(key);
		return of(filterData({ data: { list } }, query));
	}

	private findItem(list: any[], id: string): number {
		return list.findIndex(item => item._id === id);
	}

}
