/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { FilterListOptionsResponse } from '../models/sl-table.model';

const globalSearchFilter = (globalSearch: string, filteredData: any) => {
	if (globalSearch) {
		const searchStr = globalSearch.toString().toLowerCase();
		filteredData = filteredData.filter(item => {
			return Object.keys(item).some(key =>
				item[key].toString().toLowerCase().includes(searchStr)
			);
		});
	}
	return filteredData;
};

const filterSimpleRules = (ruleSet, filteredData) => {
	if (ruleSet.rules.length > 0) {
		return filteredData.filter(item => {
			return ruleSet.rules.some(rule => {
				const valueStr = rule.value.toString().toLowerCase();
				const fieldValueStr = item[rule.field].toString().toLowerCase();
				return fieldValueStr.includes(valueStr);
			});
		});
	}
	return filteredData;
};

const filterRuleSets = (ruleSet, filteredData) => {
	if (ruleSet.ruleSets.length > 0) {
		const ruleSetsResults = ruleSet.ruleSets.map(ruleSetItem => {
			return filteredData.filter(item => {
				return ruleSetItem.rules.some(rule => {
					const fieldValueStr = item[rule.field].toString().toLowerCase();
					if (Array.isArray(rule.value)) {
						return rule.value.map(fields => fields.toLowerCase()).includes(fieldValueStr);
					} else {
						const valueStr = rule.value.toString().toLowerCase();
						return fieldValueStr === valueStr;
					}
				});
			});
		});

		if (ruleSetsResults.length) {
			filteredData = ruleSetsResults.reduce((acc, resultSet) => {
				return acc.filter(item => resultSet.includes(item));
			});
		}
	}

	return filteredData;
};

const sortData = (filteredData, sortBy, sortDirection) => {
	if (sortBy && sortBy.length > 0) {
		filteredData.sort((a, b) => {
			for (let i = 0; i < sortBy.length; i++) {
				const field = sortBy[i];
				const direction = sortDirection[i] || 'asc';
				const multiplier = direction.toLowerCase() === 'asc' ? 1 : -1;

				const valueA = a[field];
				const valueB = b[field];

				if (valueA < valueB) {
					return -1 * multiplier;
				}
				if (valueA > valueB) {
					return 1 * multiplier;
				}
			}
			return 0;
		});
	}
};


export const filterData = <T>(response, query) => {

	let filteredData = response?.data?.list;

	const { sortBy, sortDirection, ruleSet, offset, limit, globalSearch } = query;

	// Global search across all fields
	filteredData = globalSearchFilter(globalSearch as string, filteredData);

	// Filter based on simple rules
	filteredData = filterSimpleRules(ruleSet, filteredData);

	// Filter based on rule sets
	filteredData = filterRuleSets(ruleSet, filteredData);

	// Sort data based on the query
	sortData(filteredData, sortBy, sortDirection);

	// Implement pagination
	const paginatedData = filteredData.slice(offset, offset + limit);

	return {
		data: {
			total: filteredData.length,
			next: offset + limit < filteredData.length ? offset + limit : null,
			previous: offset > 0 ? offset - limit : null,
			list: paginatedData,
			status: response.data.status
		}
	};
};

export const filterFiltersData = (response, query): FilterListOptionsResponse => {

	let filteredData = response?.data?.list;

	const { ruleSet, globalSearch } = query;

	/// Global search across all fields
	filteredData = globalSearchFilter(globalSearch as string, filteredData);

	// Filter based on simple rules
	filteredData = filterSimpleRules(ruleSet, filteredData);

	// Filter based on rule sets
	filteredData = filterRuleSets(ruleSet, filteredData);

	return {
		data: {
			totals: filteredData.map((data) => ({ key: data[query.countBy] }))
		}
	};
};

